import { memoize, singleton } from "@/lib/helpers/utils/memoize.ts";

import "./fontAwesome.css";

const packages = {
  /* eslint-disable @typescript-eslint/naming-convention */
  "fa-solid": { alternates: ["fas", "fa"], urlName: "solid" },
  "fa-regular": { alternates: ["far"], urlName: "regular" },
  "fa-light": { alternates: ["fal"], urlName: "light" },
  "fa-duotone": { alternates: ["fad"], urlName: "duotone" },
  "fa-thin": { alternates: ["fat"], urlName: "thin" },
  "fa-brands": { alternates: ["fab"], urlName: "brands" },
  /* eslint-enable @typescript-eslint/naming-convention */
} as const;

type PackageLong = keyof typeof packages;
type PackageAlternate = (typeof packages)[PackageLong]["alternates"][number];

const packageAlternates = Object.fromEntries(
  Object.entries(packages).flatMap(([longName, { alternates }]) =>
    alternates.map((alternate) => [alternate, longName] as const),
  ),
) as Record<PackageAlternate, PackageLong>;

const packageSelector = `.${[...Object.keys(packages), ...Object.keys(packageAlternates)].join(",.")}`;

function getIconPackageNames(classList: string[]) {
  const longNameClass = classList.find(
    (className): className is PackageLong => className in packages,
  );

  const alternateNameClass = classList.find(
    (className): className is PackageAlternate =>
      className in packageAlternates,
  );

  if (!longNameClass && !alternateNameClass) {
    return;
  }

  const longName = longNameClass ?? packageAlternates[alternateNameClass!];

  return {
    longName,
    classNames: [longName, ...packages[longName].alternates],
    urlName: packages[longName].urlName,
  };
}

const getIconCssFromClassList = memoize((classList: string[]) => {
  const packageNames = getIconPackageNames(classList);

  if (!packageNames) {
    return;
  }

  const iconName = classList.find((className) => {
    return className.startsWith("fa-") && className !== packageNames.longName;
  });

  if (!iconName) {
    return;
  }

  const selector = packageNames.classNames
    .map((className) => `.${className}.${iconName}`)
    .join(",");

  const iconUrlName = iconName.replace("fa-", "");
  const url = `https://ka-p.fontawesome.com/releases/v6.6.0/svgs/${packageNames.urlName}/${iconUrlName}.svg?token=51b4c236cf`;

  return `${selector} { background-color: currentColor; --fa-icon-url: url(${url}) }`;
});

const styleElement = singleton(() => {
  const existingEl = document.querySelector<HTMLStyleElement>(
    "style#s-font-awesome",
  );
  if (!existingEl) {
    document.head.insertAdjacentHTML(
      "beforeend",
      "<style id='s-font-awesome'></style>",
    );
  }
  return document.querySelector<HTMLStyleElement>("style#s-font-awesome")!;
});

function applyFontAwesomeIconCss() {
  const iconElements = Array.from(
    document.querySelectorAll<HTMLElement>(packageSelector),
  );

  const cssStrings = iconElements.reduce<Set<string>>((cssStrings, elem) => {
    const faClassList = Array.from(elem.classList).filter((className) =>
      className.startsWith("fa"),
    );
    const css = getIconCssFromClassList(faClassList);
    if (css) {
      cssStrings.add(css);
    }
    return cssStrings;
  }, new Set<string>());

  const cssString = Array.from(cssStrings).sort().join("\n");

  if (styleElement().innerHTML !== cssString) {
    styleElement().innerHTML = cssString;
  }
}

function initFontAwesome() {
  applyFontAwesomeIconCss();
  const observer = new MutationObserver(() => applyFontAwesomeIconCss());
  observer.observe(document.body, { childList: true, subtree: true });
}

export { initFontAwesome };
